pre code.hljs {
  padding: 1em;
  display: block;
  overflow-x: auto;
}

code.hljs {
  padding: 3px 5px;
}

.hljs {
  color: #24292e;
  background: #fff;
}

.hljs-doctag, .hljs-keyword, .hljs-meta .hljs-keyword, .hljs-template-tag, .hljs-template-variable, .hljs-type, .hljs-variable.language_ {
  color: #d73a49;
}

.hljs-title, .hljs-title.class_, .hljs-title.class_.inherited__, .hljs-title.function_ {
  color: #6f42c1;
}

.hljs-attr, .hljs-attribute, .hljs-literal, .hljs-meta, .hljs-number, .hljs-operator, .hljs-variable, .hljs-selector-attr, .hljs-selector-class, .hljs-selector-id {
  color: #005cc5;
}

.hljs-regexp, .hljs-string, .hljs-meta .hljs-string {
  color: #032f62;
}

.hljs-built_in, .hljs-symbol {
  color: #e36209;
}

.hljs-comment, .hljs-code, .hljs-formula {
  color: #6a737d;
}

.hljs-name, .hljs-quote, .hljs-selector-tag, .hljs-selector-pseudo {
  color: #22863a;
}

.hljs-subst {
  color: #24292e;
}

.hljs-section {
  color: #005cc5;
  font-weight: bold;
}

.hljs-bullet {
  color: #735c0f;
}

.hljs-emphasis {
  color: #24292e;
  font-style: italic;
}

.hljs-strong {
  color: #24292e;
  font-weight: bold;
}

.hljs-addition {
  color: #22863a;
  background-color: #f0fff4;
}

.hljs-deletion {
  color: #b31d28;
  background-color: #ffeef0;
}
/*# sourceMappingURL=index.68fd52c8.css.map */
